.highlight-wrapper {
  background-color: #111111;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.container {
  padding: 0 48px;
  box-sizing: border-box;
  margin: 0 auto;
  margin-top: 96px;
}

.highlight-heading {
  color: #fff2e3;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 25px;
  font-family: inter, sans-serif;
}

.highlight-box-main-wrapper {
  display: flex;
}

.highlight-story-wrapper {
  display: flex;
}

.highlight-story-box {
  height: 80px;
  width: 80px;
  min-width: 80px;
  border-radius: 100%;
  border: 2px solid #373737;
  padding: 5px;
  margin: 0 13px;
}

.highlight-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 240px;
  height: 380px;
  transform: translate(-50%, -50%);
  background-color: #1d1d1d;
  border: 1px solid #7b7b7b;
  padding: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  color: #fff2e3;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 140px;
  font-family: inter, sans-serif;
  font-size: 14px;
  z-index: 9999;
}

.highlight-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 2;
}

.dateContainer {
  font-weight: normal;
  margin-right: 23px;
  margin-left: auto;
  margin-top: 16px;
  width: max-content;
  line-height: 24px;
  letter-spacing: 0.38px;
  font-size: 12px;
}

.highlight-popup-width {
  width: 500px;
  gap: 5px;
  border: none;
}

.highlight-popup-heading {
  display: flex;
  justify-content: space-between;
}

.highlight-popup-heading-width {
  width: 100%;
}

.highlight-popup p {
  margin: 0;
  padding: 5px 0;
}

.highlight-username-user_name{
  font-weight: 700;
  text-transform: capitalize;
  font-size: 15px;
}

.username_you{
  text-decoration: underline;
  text-underline-offset: 3px;
}

.sideCard .highlight-username-user_name{
  text-transform: capitalize;
  font-family: Inter, sans-serif;
  font-size: 10px;
}

.highlight-popup .closebtn {
  position: absolute;
  top: -30px;
  right: -12px;
  padding: 8px 20px;
  float: right;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background: transparent;
}

.highlight-upload-img img {
  width: 500px;
}

.highlight-popup-body {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  gap: 15px;
}
.highlight-popup-body div img {
  width: 12px;
  height: 12px;
  margin-right: 6px;
}
.highlight-popup button img {
  width: 10px;
  height: 10px;
}

.highlight-popup-heading .discardBtn {
  background: #0d0d0d;
  color: #fff;
  border: 2px solid #0d0d0d;
  border-radius: 3px;
  font-size: 9px;
  padding: 4px 10px;
}

.highlight-popup-heading .saveProgressBtn {
  background: #fff2e3;
  color: #0d0d0d;
  border: 2px solid #fff2e3;
  border-radius: 3px;
  font-size: 9px;
  padding: 3px 10px;
  margin-left: 10px;
}

.highlight-popup-body .uploadtn {
  background: #0d0d0d;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.image-uploader .image-upload-input {
  display: none;
}

.highlight-username {
  color: #fff2e3;
  font-size: 15px;
  text-align: center;
  font-family: inter, sans-serif;
  margin-top: 20px;
  text-transform: capitalize;
}


.highlight-username-profile{
  height: 50px;
  width: 50px;
  border-radius: 50%;
}


.story-active {
  border: 2px solid white;
  cursor: pointer;
}

.highlight-story-image {
  border-radius: 100%;
  height: 100%;
  width: 100%;
}
.crop-container {
  width: 500px;
  height: 200px;
}

.controls {
  position: absolute;
  bottom: 5px;
  left: 50%;
  width: 40%;
  transform: translateX(-50%);
  height: 40px;
  display: flex;
  align-items: center;
}
.controls .imgIconFirst {
  margin-right: 10px;
  width: 15px;
}
.controls .imgIconLast {
  margin-left: 10px;
  width: 25px;
}

.slider {
  padding: 22px 0px;
}

input[type="range"] {
  font-size: 1.5rem;
  width: 12.5em;
}

input[type="range"] {
  color: #fff;
  --thumb-height: 0.725em;
  --track-height: 0.125em;
  --track-color: rgba(98, 98, 98, 1);
  --brightness-hover: 180%;
  --brightness-down: 80%;
  --clip-edges: 0.125em;
}

input[type="range"].win10-thumb {
  color: #2b2d42;

  --thumb-height: 1.375em;
  --thumb-width: 0.5em;
  --clip-edges: 0.0125em;
}

@media (prefers-color-scheme: dark) {
  html {
    background-color: #000;
  }

  html::before {
    background: radial-gradient(circle at center, #101112, #000);
  }

  input[type="range"] {
    color: #f07167;
    --track-color: rgba(255, 255, 255, 0.1);
  }

  input[type="range"].win10-thumb {
    color: #3a86ff;
  }
}

input[type="range"] {
  position: relative;
  background: #fff0;
  overflow: hidden;
}

input[type="range"],
input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  transition: all ease 100ms;
  height: var(--thumb-height);
}

input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-webkit-slider-thumb {
  position: relative;
}

input[type="range"]::-webkit-slider-thumb {
  --thumb-radius: calc((var(--thumb-height) * 0.5) - 1px);
  --clip-top: calc((var(--thumb-height) - var(--track-height)) * 0.5 - 0.5px);
  --clip-bottom: calc(var(--thumb-height) - var(--clip-top));
  --clip-further: calc(100% + 1px);
  --box-fill: calc(-100vmax - var(--thumb-width, var(--thumb-height))) 0 0
    100vmax currentColor;

  width: var(--thumb-width, var(--thumb-height));
  background: linear-gradient(currentColor 0 0) scroll no-repeat left center /
    50% calc(var(--track-height) + 1px);
  background-color: currentColor;
  box-shadow: var(--box-fill);
  border-radius: var(--thumb-width, var(--thumb-height));

  filter: brightness(100%);
  clip-path: polygon(
    100% -1px,
    var(--clip-edges) -1px,
    0 var(--clip-top),
    -100vmax var(--clip-top),
    -100vmax var(--clip-bottom),
    0 var(--clip-bottom),
    var(--clip-edges) 100%,
    var(--clip-further) var(--clip-further)
  );
}

input[type="range"]::-webkit-slider-runnable-track {
  background: linear-gradient(var(--track-color) 0 0) scroll no-repeat center /
    100% calc(var(--track-height) + 1px);
}

input[type="range"],
input[type="range"]::-moz-range-track,
input[type="range"]::-moz-range-thumb {
  appearance: none;
  transition: all ease 100ms;
  height: var(--thumb-height);
}

input[type="range"]::-moz-range-track,
input[type="range"]::-moz-range-thumb,
input[type="range"]::-moz-range-progress {
  background: #fff0;
}

input[type="range"]::-moz-range-thumb {
  background: currentColor;
  border: 0;
  width: var(--thumb-width, var(--thumb-height));
  border-radius: var(--thumb-width, var(--thumb-height));
  cursor: grab;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  background: var(--track-color);
}

input[type="range"]::-moz-range-progress {
  appearance: none;
  background: currentColor;
  transition-delay: 30ms;
}

input[type="range"]::-moz-range-track,
input[type="range"]::-moz-range-progress {
  height: calc(var(--track-height) + 1px);
  border-radius: var(--track-height);
}

input[type="range"]::-moz-range-thumb,
input[type="range"]::-moz-range-progress {
  filter: brightness(100%);
}

.upload-story {
  margin-left: 0;
  position: relative;
}

.story-upload-btn {
  height: 38px;
  width: 38px;
  border-radius: 100%;
  border: 1px solid #323232;
  background-color: #323232;
  cursor: pointer;
  position: absolute;
  bottom: -10px;
  right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.story-upload-icon {
  width: 17px;
  height: 17px;
  cursor:pointer;
}

.headerContainer .navbar {
  height: 76px;
  width: 100%;
  display: flex;
}
.headerContainer {
  background: #1d1d1d;
  display: flex;
  padding-left: 48px;
  padding-right: 48px;
}

.headerContainer .navbar .nav {
  width: 293px;
  display: flex;
  justify-content: space-between;
  margin-right: 2px;
}

.headerContainer .navbar .icon-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 152.33px;
}

.headerContainer .navbar .end-data-container {
  width: 363.33px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerContainer .navbar .end-data-container button {
  margin-right: 2px;
}

.headerContainer .navbar .mx-2 {
  margin-left: 2px;
  margin-right: 2px;
}

.reactEasyCrop_Container {
  height: 244px;
  width: 500px;
  top: 50px !important;
  left: 20px !important;
}

.reactEasyCrop_CropArea {
  border: 2px solid #fff2e3 !important;
}

.cornerBox {
  width: 20px;
  height: 20px;
  border: 6px solid #fff2e3;
  position: absolute;
  z-index: 2;
}
.cornerBox1 {
  left: 31.2%;
  border-right-style: none;
  border-bottom-style: none;
  top: 11.4%;
  border-radius: 4px 0px 0px 0px;
}
.cornerBox2 {
  right: 31.2%;
  border-bottom-style: none;
  border-left-style: none;
  top: 11.4%;
  border-radius: 0px 4px 0px 0px;
}

.cornerBox3 {
  right: 31.2%;
  bottom: 11.4%;
  border-left-style: none;
  border-top-style: none;
  border-radius: 0px 0px 4px 0px;
}
.cornerBox4 {
  left: 31.2%;
  bottom: 11.4%;
  border-right-style: none;
  border-top-style: none;
  border-radius: 0px 0px 0px 4px;
}

.reactEasyCrop_CropArea::before,
.reactEasyCrop_CropArea::after {
  border: none !important;
}

.logo-center {
  align-self: center;
  cursor: pointer !important;
  margin-right: 47px;
}

.headerContainer .navbar .nav .nav-item {
  color: #fff2e3;
  border-width: 0;
  border-bottom: 2px solid;
  border-bottom-color: transparent;
  border-image: none;
  background-color: transparent;
  background-image: none;
  height: 48px;
  padding-top: 26px;
  font-family: inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  width: max-content;
  text-align: left;
}

.headerContainer .navbar .nav .nav-item.active {
  border-image: linear-gradient(
      90.83deg,
      #3fa7e1 -0.01%,
      #7699ef 31.13%,
      #ae9df2 51.64%,
      #e8a5ca 72.11%,
      #f1b2c6 99.99%
    )
    2% stretch;
  background-image: linear-gradient(
    90.83deg,
    #3fa7e1 -0.01%,
    #7699ef 31.13%,
    #ae9df2 51.64%,
    #e8a5ca 72.11%,
    #f1b2c6 99.99%
  );
  -webkit-background-clip: text; /* For Safari */
  -moz-background-clip: text; /* For Firefox */
  background-clip: text;
  color: transparent;
  border-image-slice: fill;
  border-image-repeat: round;
  font-weight: 700;
}

.headerContainer .searchContainer {
  height: 44px;
  align-self: center;
  background: #252525;
  border-radius: 32px;
  display: flex;
  align-items: center;
  width: 461px;
  margin: 0 auto;
}
.headerContainer .searchContainer img {
  margin-left: 10px;
  margin-right: 7px;
}

.headerContainer .searchContainer input {
  background-color: transparent;
  color: #fff2e3;
  border: none;
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.73px;
  text-align: left;
  width: 90%;
}

.headerContainer .searchContainer input:focus,
.inspiration-container .inspiration-text:focus {
  outline: none;
}

.headerContainer .searchContainer input::placeholder {
  color: #676767;
  font-family: Inter, sans-serif;
}

.headerContainer .navbar .share-project-btn {
  border-radius: 7px;
  height: 44px;
  width: 155px;
  border: none;
  background-image: url("../../assets/defaultBtn.png");
  background-repeat: no-repeat;
}

.headerContainer .navbar .share-project-btn.selected-btn {
  background-image: url("../../assets/selectedBtn.png");
}

.pointer-cursor {
  cursor: -moz-pointer;
  cursor: pointer;
}

.highlight-container {
  display: flex;
  align-items: center;
  overflow: auto;
  white-space: nowrap;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.highlight-container::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.highlight-items-container {
  display: flex;
  flex-wrap: nowrap;
  transition: transform 0.3s ease;
}

.highlight-item {
  flex: 0 0 auto;
  width: 100px; /* Adjust as needed */
  height: 100px; /* Adjust as needed */
  background-color: lightblue;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.highlight-slider-button {
  cursor: pointer;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  z-index: 1;
}

.highlight-left {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  position: sticky;
  bottom: 78px;
 
}

.highlight-right {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  position: sticky;
  right: 0px;
  bottom: 78px;
}

.arrowDisabled {
  display: none;
}

.hightlight-viewer {
  position: sticky;
  top: 77px;
  left: 0;
  width: 100%;
  display: flex;
  gap: 45px;
  padding-bottom: 30px;
  align-items: center;
  justify-content: center;
  margin-top: 76px;
}

.hightlight-user-info {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  
}

.hightlight-user-info img {
  width: 50px; /* Adjust size as needed */
  height: 50px; /* Adjust size as needed */
  border-radius: 50%; /* Make the image circular */
  margin-right: 10px;
}

.hightlight-user-info span {
  font-size: 18px; /* Adjust size as needed */
  color: #fff; /* White text color */
}

.hightlight-story-content {
  background-repeat: no-repeat;
}

.hightlight-button {
  background-color: transparent;
  border: none;
  color: #fff; /* White text color */
  font-size: 16px; /* Adjust size as needed */
  cursor: pointer;
  margin-top: 20px;
}

.highlight-view-box-wrapper {
  display: flex;
  margin-top: 25px;
  font-size: 12px;
  justify-content: flex-end;
  margin-left: auto;
  color: #fff;
  gap: 3px;
  font-family: Inter, sans-serif;
}

.highlight-view-box-wrapper img{
  width: 15px;
}

.highlight_top_view_bar {
  display: flex;
  gap: 5px;
  margin: 7px;
}
.highlight_top_view_bar_section {
  background: #7d7d7d;
  height: 3px;
  width: 100%;
  border-radius: 5px;
}
.bar_section_selected {
  background: #fff2e3;
}

.highlight-view-card {
  margin-top: 50px;
  width: 490px;
  height: 820px;
  /* border: 0.5px solid #7b7b7b; */
  padding: 10px;
  border-radius: 4px;
  z-index: 2;
  background: none;
  background-repeat: no-repeat;
color: #FFF2E3;
  background-position: center;
  background-size: cover;
 
}

.img_main{
  position: absolute;
  border-radius: 15px;
}

.main_side_image_div{
  position: relative;
}

#main_side_image_div_date{
  position: absolute;
  font-size: 15;
  margin-left: 80;
  margin-top: 10;
  font-weight: 400;
}

.side_img{
  width: 100%;
  height: 100%;
  border-radius: 7px;
}


.highlight-username-location{
  font-size: 10px;
  margin-top: 6px;
}

.highlight-username-view {
  display: flex;
  gap: 11px;
  margin-right: 60px;
  width: 240px;
}

.sideCard .highlight-username-view {
  position: relative;
  left: 79px;
  font-size: 10px;
  margin-right: 72px;

}

.highlight-username-view img {
  border-radius: 50%;
  width: 45px;
  height: 45px;
}

.likeIconWrapper img
 {
  width: 22px;
  height: 19px;
}

.viewIconWrapper img {
  width: 25px;
}

.likeIconWrapper {
  display: flex;
  height: 20px;
  align-items: center;
  gap: 10px;
  font-weight: 700;
  width: 40px;
  margin-right: 10px;
  margin-top: 7px;
  margin-left: 30px;
}

.viewIconWrapper {
  margin-left: 10px;
  display: flex;
  height: 20px;
  align-items: center;
  gap: 10px;
  font-weight: 700;
  width: 40px;
  margin-right: 30px;
  margin-top: 7px;
}

.highlight-control-button {
  height: 85%;
}

.no-story{
  border-color: transparent;
}

.highlight-control-button button {
 
  width: 50%;
  height: 100%;
  background: transparent;
  opacity: 0;
  z-index: 99;
}



.highlight-story-section img {
  width: 200px;
  height: 300px;
}
.discover-project-container .card-style {
  max-width: 100%;
  height: 260px;
  border-radius: 10px;
  width: 378.33px;
  margin-bottom: 8px;
}

.discover-projectDetails-container .card-style {
  max-width: 100%;
  height: 260px;
  border-radius: 10px;
  width: 380px;
  margin-bottom: 8px;
}

.discover-project-container .project-title,
.discover-projectDetails-container .project-title {
  padding-right: 10px;
}

.discover-project-container .d-none,
.discover-projectDetails-container .d-none {
  display: none;
}

.discover-project-container .card-style img,
.discover-projectDetails-container .card-style img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.discover-project-container .title-container {
  width: 378.33px !important;
}

.discover-projectDetails-container .title-container {
  width: 380px !important;
}

.discover-project-container .options-position {
  margin-right: 19px;
  margin-top: 33px;
  cursor: pointer;
}

.discover-project-container .card-label,
.discover-projectDetails-container .card-label {
  font-family: Inter-SemiBold, sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.38px;
  color: #fff2e3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.discover-project-container .card-text,
.discover-projectDetails-container .card-text {
  font-family: Inter, sans-serif;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.38px;
  color: #fff2e3;
}

.discover-project-container .manage-space,
.discover-project-container .manage-space-feature,
.discover-projectDetails-container .manage-space,
.discover-projectDetails-container .manage-space-feature {
  display: flex;
  align-items: center;
}

.discover-project-container .manage-space-feature,
.discover-projectDetails-container .manage-space-feature {
  gap: 2px;
}

.discover-project-container .manage-space,
.discover-projectDetails-container .manage-space {
  gap: 8px;
}

.discover-project-container .eyeIcon,
.discover-projectDetails-container .eyeIcon {
  width: 24.2px;
  height: 17.6px;
}

.discover-project-container .likeIcon,
.discover-projectDetails-container .likeIcon {
  width: 18.7px;
  height: 16.5px;
}

.discover-project-container .featureIcon,
.discover-projectDetails-container .featureIcon {
  width: 22px;
  height: 22px;
}

.discover-project-container .view-more-btn {
  width: 145px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 55px;
}

.discover-project-container .view-more-btn:active {
  background-color: #252525;
}

.work-opportunity-container .view-more-btn {
  width: max-content;
  font-family: Inter,sans-serif;
  font-size: 16px;
  line-height: 19.36px;
  position: absolute;
  height: auto;
  background-color: transparent;
  padding: 0;
  right: 50px;
  top: 50px;
  border-radius: 0;
  border-bottom: 1px solid #fff2e3;
}

.discover-project-container .select-container,
.discover-project-container .tags-container {
  display: flex;
  align-items: center;
  column-gap: 24px;
  margin-top: 40px;
}

.discover-project-container .tags-container,
.work-opportunity-container .tags-container,
.like-views-container .likeViews-elm-container {
  flex-wrap: nowrap;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.work-opportunity-container .tags-container {
  padding-right: 30px;
}

.discover-project-container .heading-text,
.work-opportunity-container .heading-text {
  font-family: Inter-SemiBold, sans-serif;
  font-size: 36px;
  line-height: 43.57px;
  color: var(--secondary-color);
}

.discover-projectDetails-container .heading-text {
  font-family: Inter-SemiBold,sans-serif;
  font-size: 32px;
  line-height: 24px;
  letter-spacing: 0.38px;
  color: var(--secondary-color);
  margin-bottom: 52px;
}

.discover-projectDetails-container .user-info-container {
  display: flex;
  justify-content: space-between;
}

.discover-projectDetails-container .user-details {
  display: flex;
  gap: 20px
}

.discover-projectDetails-container .user-name {
  font-family: Inter-Medium,sans-serif;
  font-size: 24px;
  line-height: 29.05px;
  color: var(--secondary-color);
  margin-bottom: 10px;
}

.discover-projectDetails-container .follow-section {
  display: flex;
  gap: 12px;
}

.discover-projectDetails-container .follow-section span,
.discover-projectDetails-container .getin-touch-section span,
.discover-projectDetails-container .user-collection,
.discover-projectDetails-container .reply-btn {
  font-family: Inter-SemiBold,sans-serif;
  font-size: 16px;
  line-height: 19.36px;
  color: var(--secondary-color);
  border-bottom: 1px solid #fff2e3;
}

.discover-projectDetails-container .user-collection {
  text-decoration: underline;
  border-bottom: none !important;
}

.discover-projectDetails-container .follow-section span,
.discover-projectDetails-container .getin-touch-section span,
.discover-projectDetails-container .user-collection,
.discover-projectDetails-container .reply-btn {
  width: max-content;
  cursor: pointer;
}

.discover-projectDetails-container .reply-btn {
  margin-left: 8px;
}

.discover-projectDetails-container .commented-at {
  margin-right: 8px;
}

.discover-projectDetails-container .commented-by {
  margin-right: 16px;
  color: #fff !important;
}

.discover-projectDetails-container .getin-touch-section span {
  line-height: 24px !important;
  letter-spacing: 0.38px !important;
}

.discover-projectDetails-container .gap-80 {
  gap: 80px;
  display: flex;
}

.discover-projectDetails-container .project-image-container {
  width: 1008px;
  height: 775px;
  margin-bottom: 24px;
  margin-top: 24px;
  position: relative;
  display: flex;
  justify-content: center;
}

.discover-projectDetails-container .left-arrow,
.discover-projectDetails-container .right-arrow {
  position: absolute;
  width: 53px;
  height: 53px;
  bottom: 20%;
}

.discover-projectDetails-container .right-arrow {
  transform: rotate(180deg);
  right: -16%;
}

.discover-projectDetails-container .left-arrow {
  left: -16%;
}

.discover-projectDetails-container .description-label {
  max-width: 1008px;
  margin-bottom: 16px;
  color: #8d8d8d !important;
}

.discover-projectDetails-container .max-w-1008 {
  max-width: 1008px;
}

.discover-projectDetails-container .comment-section {
  border-top: 1px solid #5F5F5F;
  padding-top: 59px;
  margin-top: 94px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.discover-projectDetails-container .commented-data {
  display: flex;
  gap: 25px;
  align-items: center;
  justify-content: space-between;
  max-width: 1084px;
}

.discover-projectDetails-container .commented-data-user {
  display: flex;
  flex-direction: column;
  gap: 25px;
  max-width: 1084px;
}

.discover-projectDetails-container .action-container-elm {
  display: flex;
  border-radius: 12px;
  background-color: #222;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 48px;
  padding: 48px 10px;
  width: 160px;
  height: max-content;
}

.discover-projectDetails-container .action-elm {
  display: flex;
  gap: 16px;
  align-items: center;
  font-family: Inter-SemiBold,sans-serif;
  font-size: 16px;
  line-height: 13.12px;
  color: #fff2e3;
  cursor: pointer;
}

.discover-projectDetails-container .action-elm .text-data {
  font-family: Inter-SemiBold,sans-serif;
  font-size: 16px;
  line-height: 19.36px !important;
  width: max-content;
  border-bottom: 1px solid #fff2e3;
  color: #fff2e3;
}

.discover-projectDetails-container .delete-comment {
  border-radius: 50%;
  background-color: #545454;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.discover-projectDetails-container .delete-comment img {
  width: 13.9px;
  height: 13.9px;
}

.discover-projectDetails-container .commented-user {
  display: flex;
  gap: 24px;
}

.discover-projectDetails-container .commented-by-user {
  width: 52.98px;
  height: 52.98px;
  border-radius: 50%;
}

.discover-projectDetails-container .comment-text {
  margin-top: 12px;
  color: #8c8c8c !important;
}

.discover-projectDetails-container .card-section {
  border-top: 1px solid #5F5F5F;
  margin-top: 270px;
  padding-top: 57px;
  padding-left: 80px;
  padding-right: 20px;
  padding-bottom: 50px;
}

.discover-projectDetails-container .card-container {
  margin-top: 27px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.discover-projectDetails-container .comment-container {
  margin-bottom: 68px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  max-width: 1084px;
}

.discover-projectDetails-container .comment-elm {
  height: 80px;
  border-radius: 37.47px;
  border: 1.29px solid #2F2F2F;
  display: flex;
}

.discover-projectDetails-container .comment-elm input:focus,
.discover-projectDetails-container .replyTextElm-container input:focus {
  outline: none;
}

.discover-projectDetails-container .comment-elm input,
.discover-projectDetails-container .replyTextElm-container input {
  color: #fff !important;
  border: none;
  padding-left: 26px;
  padding-right: 26px;
}

.discover-projectDetails-container .replyTextElm-container input {
  background-color: #222;
  max-width: 1002px;
  height: 56px;
  border-radius: 37.47px;
  width: 100%;
}

.discover-projectDetails-container .comment-elm input {
  height: 80px;
  background-color: transparent;
  border-top-left-radius: 37.47px;
  border-bottom-left-radius: 37.47px;
  width: 80%;
}

.discover-projectDetails-container .add-comment-btn {
  height: 80px;
  border: none;
  border-left: 1.29px solid #3C3C3C;
  background-color: transparent;
  border-top-right-radius: 37.47px;
  border-bottom-right-radius: 37.47px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 15%;
}

.discover-projectDetails-container .cancel-btn {
  font-family: Inter-SemiBold,sans-serif;
  font-size: 16px;
  line-height: 19.36px;
  color: #fff;
  width: max-content;
  cursor: pointer;
  border-bottom: 1px solid #fff;
}

.discover-projectDetails-container .project-image-container img {
  width: 100%;
  height: 100%;
}

.discover-projectDetails-container .right-text {
  font-family: Inter,sans-serif;
  font-size: 16px;
  line-height: 19.36px;
  color: #8d8d8d;
  display: flex;
  gap: 9px;
  align-items: flex-end;
}

.discover-projectDetails-container .default-text {
  font-family: Inter,sans-serif;
  font-size: 16px;
  line-height: 19.36px;
  color: var(--secondary-color);
}

.discover-project-container,
.work-opportunity-container {
  background-color: #111111;
  margin-top: 40px;
  padding-bottom: 40px;
}

.work-opportunity-container {
  position: relative;
  padding-right: 0;

}

.discover-project-container .card-container,
.discover-projectDetails-container .card-container {
  display: flex;
  column-gap: 46px; 
  row-gap: 55px; 
  margin-top: 55px; 
  margin-left: 55px; 
  margin-right: 10px;
  flex-wrap: wrap; 
}

.discover-project-container .select-btn {
  background-color: transparent;
  font-family: Inter-SemiBold, sans-serif;
  font-size: 14px;
  line-height: 16.94px;
  display: flex;
  align-items: center;
  color: #fff2e3;
  padding: 0;
  border: none;
  justify-content: center;
}

.closeViewHighlightBackdrop {
  z-index: 2;
  position: absolute;
  top: 40px;
  left: 40px;
  color: #fff2e3;
  font-family: Inter-SemiBold, sans-serif;
  background: none;
  border: 1px solid transparent;
  font-size: 36px;
  line-height: 43.57px;
}


.closeViewHighlightBackdrop img{
  margin-right: 19px;
  position: relative;
  bottom: 3px;
  width: 9px;
  height: 17px;
}

.highlight-view-slider-button {
  cursor: pointer;
   z-index: 2;
}

.sideCard {
  width: 226.21px;
  height: 414.58px;
  margin-left: -10px;
  margin-right: -10px;
  opacity: 0.4;
  background: none;
}

.sideCard .highlight-view-box-wrapper{
  margin-top: 0px;
  font-size: 6px;
}

.sideCard .highlight-view-box-wrapper img{
  width: 10px;
}

.opacityZero {
  opacity: 0;
}

.discover-project-container .manage-gap,
.discover-projectDetails-container .manage-gap {
  column-gap: 19px;
}
.discover-projectDetails-container .user-profile-pic {
  width: 76px;
  height: 76px;
  border-radius: 50%;
}

.discover-projectDetails-container .user-data-container {
  display: flex;
  align-items: center;
  gap: 8px
}

.discover-projectDetails-container .replyTextElm-container {
  display: flex;
  gap: 28px;
  align-items: center;
}

.discover-projectDetails-container .bullet-container {
  display: flex;
  position: absolute;
  bottom: 10%;
  gap: 4px;
  width: 100%;
  border-radius: 2px;
  max-width: 400px;
  justify-content: space-between;
}

.discover-projectDetails-container .indicators {
  width: 8px;
  height: 8px;
  border: 1px solid #fff2e3;
  background-color: #171717;
  cursor: pointer;
}

.discover-projectDetails-container .indicators.active {
  background-color: #fff;
}

@media (max-width: 1860px) {
  .hightlight-viewer{
    gap: 30px;
  }
  .highlight-view-card{
    width: 460px;
    height: 650px;
  }
  .sideCard {
    width: 220px;
    height: 350px ;
  }
  .highlight-username-view{
    width: 210px;
  }
  .sideCard .highlight-username-view {
    left: 65px;
  }
}
@media (max-width: 1800px) {
  .hightlight-viewer{
    gap: 30px;
  }
  .highlight-view-card{
    width: 460px;
    height: 650px;
  }
  .sideCard {
    width: 220px;
    height: 350px ;
  }
  .highlight-username-view{
    width: 210px;
  }
  .sideCard .highlight-username-view {
    left: 60px;
  }
}
@media (max-width: 1750px) {
  .hightlight-viewer{
    gap: 30px;
  }
  .highlight-view-card{
    width: 420px;
    height: 650px;
  }
  .sideCard {
    width: 210px;
    height: 350px ;
  }
  .highlight-username-view{
    width: 200px;
  }
  .sideCard .highlight-username-view {
    left: 75px;
  }
}

@media (max-width: 1600px) {
  .hightlight-viewer{
    gap: 30px;
  }
  .highlight-view-card{
    width: 400px;
    height: 650px;
  }
  .sideCard {
    width: 180px;
    height: 320px ;
  }
  .highlight-username-view{
    width: 150px;
  }
  .sideCard .highlight-username-view {
    left: 85px;
  }
}
@media (max-width: 1450px) {
  .hightlight-viewer{
    gap: 30px;
  }
  .highlight-view-card{
    width: 350px;
    height: 650px;
  }

  .sideCard {
    width: 173px;
    height: 320px ;
  }
  .highlight-username-view{
    width: 110px;
  }
  .sideCard .highlight-username-view {
    left: 72px;
  }
  .dateContainer {
    font-size: 10px;
    margin-right: 12px;
    margin-top: 10px !important;
  }
}

@media (max-width: 1400px) {
  #main_side_image_div_date{
    margin-left: 70;
  }
  .hightlight-viewer{
    gap: 30px;
  }
  .highlight-view-card{
    width: 350px;
    height: 580px;
  }
  .sideCard {
    width: 162px;
    height: 300px ;
  }
  .sideCard .highlight-username-user_name{
    font-size: 8px;
  }
  .highlight-view-slider-button{
    width: 40px;
  }
  .dateContainer {
    font-size: 10px !important;
    margin-right: 12px;
    margin-top: 10px !important;
  }
  .rightArrow img {
    margin-left: -15px;
  }
}
@media (max-width: 1300px) {
  #main_side_image_div_date{
    margin-left: 60;
  }
  .hightlight-viewer{
    gap: 25px;
  }
  .highlight-view-card{
    width: 330px;
    height: 530px;
  }
  .likeIconWrapper {
    width:25px
  }
  .sideCard {
    width: 153px;
    height: 280px ;
  }
  .sideCard .highlight-username-user_name{
    font-size: 7px;
  }
  .highlight-view-slider-button{
    width: 40px;
  }
  .dateContainer {
    font-size: 10px !important;
  }
  .rightArrow img {
    margin-left: -13px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1369px) {
  .discover-project-container .card-style,
  .discover-projectDetails-container .card-style {
    width: 264px !important;
    height: 182.4px;
  }
  .discover-project-container .project-title,
  .discover-projectDetails-container .project-title {
    max-width: 100px;
    padding-right: 6px;
  }
  .discover-project-container .manage-gap,
  .discover-projectDetails-container .manage-gap {
    column-gap: 10px;
  }
  .discover-project-container .title-container,
  .discover-projectDetails-container .title-container {
    width: 264px !important;
  }
  .discover-project-container .card-label,
  .discover-projectDetails-container .card-label {
    font-size: 12px;
  }
  .discover-project-container .card-text,
  .discover-projectDetails-container .card-text {
    font-size: 10px;
  }
  .discover-project-container .card-label,
  .discover-project-container .card-text,
  .discover-projectDetails-container .card-label,
  .discover-projectDetails-container .card-text {
    line-height: 16px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1460px) {
  .discover-project-container .card-container {
    column-gap: 32px !important;
    margin-left: 16px !important;
  }
}

@media screen and (min-width: 1370px) and (max-width: 1460px) {
  .discover-project-container .card-style,
  .discover-projectDetails-container .card-style {
    width: 288px !important;
    height: 198.4px;
  }
  .discover-project-container .title-container,
  .discover-projectDetails-container .title-container {
    width: 288px !important;
  }
  .discover-project-container .project-title,
  .discover-projectDetails-container .project-title {
    max-width: 114px;
    padding-right: 6px;
  }
  .discover-project-container .card-text,
  .discover-projectDetails-container .card-text {
    font-size: 10px;
  }
  .discover-project-container .manage-gap,
  .discover-projectDetails-container .manage-gap {
    column-gap: 10px;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1460px) {
  .discover-project-container .card-container,
  .discover-projectDetails-container .card-container {
    margin-left: 32px !important;
  }
  .discover-project-container .manage-gap,
  .discover-projectDetails-container .manage-gap {
    column-gap: 12px;
  }
}

@media screen and (min-width: 1461px) and (max-width: 1790px) {
  .discover-project-container .card-style,
  .discover-projectDetails-container .card-style {
    width: 302.4px !important;
    height: 208px;
  }
  .discover-project-container .project-title,
  .discover-projectDetails-container .project-title {
    max-width: 120px;
    padding-right: 8px;
  }
  .discover-project-container .card-text,
  .discover-projectDetails-container .card-text {
    font-size: 12px;
  }
  .discover-project-container .title-container,
  .discover-projectDetails-container .title-container {
    width: 302.4px !important;
  }
  .discover-project-container .manage-gap,
  .discover-projectDetails-container .manage-gap {
    column-gap: 14px;
  }
  .discover-project-container .card-container,
  .discover-projectDetails-container .card-container {
    column-gap: 32px !important;
    margin-left: 32px !important;
  }
}

@media screen and (min-width: 1500px) and (max-width: 1560px) {
  .discover-project-container .card-container,
  .discover-projectDetails-container .card-container {
    margin-left: 55px !important;
  }
}

@media screen and (min-width: 1561px) and (max-width: 1660px) {
  .discover-project-container .card-container,
  .discover-projectDetails-container .card-container {
    column-gap: 46px !important;
  }
  .discover-project-container .card-style,
  .discover-projectDetails-container .card-style {
    width: 320px !important;
    height: 220.8px;
  }
  .discover-project-container .project-title,
  .discover-projectDetails-container .project-title {
    max-width: 140px;
    padding-right: 8px;
  }
  .discover-project-container .card-text,
  .discover-projectDetails-container .card-text {
    font-size: 12px;
  }
  .discover-project-container .manage-gap,
  .discover-projectDetails-container .manage-gap {
    column-gap: 12px;
  }
  .discover-project-container .title-container,
  .discover-projectDetails-container .title-container {
    width: 320px !important;
  }
}

@media screen and (min-width: 1661px) and (max-width: 1800px) {
  .discover-project-container .card-container,
  .discover-projectDetails-container .card-container {
    column-gap: 46px !important;
  }
  .discover-project-container .card-style,
  .discover-projectDetails-container .card-style {
    width: 324.8px !important;
    height: 224px;
  }
  .discover-project-container .project-title,
  .discover-projectDetails-container .project-title {
    max-width: 135px;
    padding-right: 8px;
  }
  .discover-project-container .card-text,
  .discover-projectDetails-container .card-text {
    font-size: 12px;
  }
  .discover-project-container .manage-gap,
  .discover-projectDetails-container .manage-gap {
    column-gap: 16px;
  }
  .discover-project-container .title-container,
  .discover-projectDetails-container .title-container {
    width: 324.8px !important;
  }
}

@media screen and (min-width: 1801px) and (max-width: 1920px) {
  .discover-project-container .card-style,
  .discover-projectDetails-container .card-style {
    width: 352px !important;
    height: 241.6px;
  }
  .discover-project-container .project-title,
  .discover-projectDetails-container .project-title {
    max-width: 157px;
    padding-right: 10px;
  }
  .discover-project-container .manage-gap,
  .discover-projectDetails-container .manage-gap {
    column-gap: 19px;
  }
  .discover-project-container .title-container,
  .discover-projectDetails-container .title-container {
    width: 352px !important;
  }
}

@media (prefers-color-scheme: dark) {
  input[type=range] {
    color: #fff !important;
    --track-color: hsla(0,0%,100%,0.1) !important;
  }
}

.likeIconWrapperBtn{
  border: none;
  background: none;
}

.calenderClass{
  position: absolute;
  right: 60px;
  top: 35px;
}

.highlight-wrapper .fixed-top {
  position: fixed;
  width: 100%;
  z-index: 999;
}

.like-views-container .right-text-container {
  margin-left: auto;
  font-size: 16px;
  line-height: 19.36px;
  border-bottom: 1px solid #fff2e3;
  height: max-content;
  color: #fff2e3;
  margin-right: 30px;
}

.discover-projectDetails-container .active-text,
.like-views-container .active-text {
  border-bottom: 1px solid !important;
  border-image: linear-gradient(90.83deg, #3FA7E1 -0.01%, #7699EF 31.13%, #AE9DF2 51.64%, #E8A5CA 72.11%, #F1B2C6 99.99%) 2% stretch !important;
  background-image: linear-gradient(90.83deg, #3FA7E1 -0.01%, #7699EF 31.13%, #AE9DF2 51.64%, #E8A5CA 72.11%, #F1B2C6 99.99%) !important;
  -webkit-background-clip: text; /* For Safari */
  -moz-background-clip: text; /* For Firefox */
  background-clip: text;
  color: transparent;
  border-image-slice: fill;
  border-image-repeat: round;
  font-family: Inter-SemiBold, sans-serif;
}


.discover-project-container .tags-container::-webkit-scrollbar,
.work-opportunity-container .tags-container::-webkit-scrollbar,
.inspiration-container .board-elm-container::-webkit-scrollbar,
.like-views-container .likeViews-elm-container::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.discover-projectDetails-container .MuiDialog-paper {
  max-width: 100% !important;
}

@media screen and (max-width: 1700px) {
  .discover-projectDetails-container .gap-80 {
    gap: 40px;
  }
  .discover-projectDetails-container .project-image-container,
  .discover-projectDetails-container .description-label,
  .discover-projectDetails-container .max-w-1008 {
    max-width: 900px;
    max-height: 600px;
  }
  .discover-projectDetails-container .left-arrow {
    left: -13%;
  }
  .discover-projectDetails-container .right-arrow {
    right: -13%;
  }
}

@media screen and (max-width: 1460px) {
  .discover-projectDetails-container .gap-80 {
    gap: 20px;

  }
  .discover-projectDetails-container .project-image-container,
  .discover-projectDetails-container .description-label,
  .discover-projectDetails-container .max-w-1008 {
    max-width: 800px;
    max-height: 500px;
  }
  .discover-projectDetails-container .card-container {
    column-gap: 16px;
    margin-left: 0;
  }
  .discover-projectDetails-container .left-arrow {
    left: -13%;
  }
  .discover-projectDetails-container .right-arrow {
    right: -13%;
  }
}

/* Inspiration section */
.inspiration-container,
.like-views-container,
.project-details-container,
.share-details-container {
  /* position: absolute; */
  top: 100px;
  background-color: #1d1d1d;
  border-radius: 14.26px;
  z-index: 9999;
  width: 505px;
  padding-top: 38px;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* left: 35%; */
}

.data-inspiration-container,
.data-showDetails-container,
.data-likeViews-container {
  box-shadow: none !important;
}

.inspiration-backdrop,
.like-views-backdrop,
.project-details-backdrop,
.share-details-backdrop {
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.inspiration-container .add-inspiration {
  display: flex;
  align-items: center;
  gap: 10px
}

.inspiration-container .add-inspiration span {
  font-family: Inter-SemiBold,sans-serif !important;
  color: #fff2e3;
  border-bottom: 1px solid #fff2e3;
  width: max-content;
  cursor: pointer;
}

.inspiration-container .element-container {
  margin-left: 36px;
  margin-right: 38px;
}

.inspiration-container .header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inspiration-container .select-board-level {
  margin-top: 21px;
  margin-bottom: 10px;
  font-family: Inter,sans-serif;
  font-size: 14px;
  line-height: 16.94px;
  color: #7d7d7d;
}

.inspiration-container .board-elm-container {
  width: 421px;
  border-radius: 10px;
  border: 0.67px solid #2F2F2F;
  max-height: 219px;
  min-height: 50px;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.inspiration-container .board-elm-container .board-elm {
  height: 86px;
  border-bottom: 0.67px solid #323232;
  display: flex;
  align-items: center;
  padding-left: 13px;
  padding-right: 30px;
}

.inspiration-container .board-elm-container .board-elm .color-container {
  width: 77.95px;
  height: 51.74px;
  border-radius: 8.05px;  
}

.inspiration-container .board-elm-container .board-elm .text-container {
  font-family: Inter-SemiBold,sans-serif;
  font-size: 14px;
  line-height: 16.94px;
  color: #fff2e3;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-left: 18px;
}

.inspiration-container .board-elm-container .board-elm .image-container {
  width: 27px;
  height: 27px;
  margin-left: auto;
}

.inspiration-container .board-elm-container .board-elm:first-child {
  border-radius: 10px 10px 0 0;
}

.inspiration-container .board-elm-container .board-elm:last-child {
  border-radius: 0 0 10px 10px;
  border-bottom: none;
}

.inspiration-container .board-elm-container .board-elm.active {
  background-color: #323232;
}

.inspiration-container .inspiration-text {
  background-color: transparent;
  border: none;
  font-family: Inter-SemiBold,sans-serif;
  font-size: 14px;
  line-height: 16.94px;
  color: #fff2e3;
  margin-left: 20px;
}

.inspiration-container .inspiration-text::placeholder {
  color: "#666";
  font-style: italic;
}

.inspiration-container .save-inspiration {
  font-family: Inter-Medium,sans-serif !important;
  width: 99px;
  margin-top: 40px;
}

.inspiration-container .add-inspiration-data {
  font-family: Inter-SemiBold,sans-serif;
  font-size: 16px;
  line-height: 19.36px;
  border-bottom: 1px solid #fff2e3;
  color: #fff2e3;
  margin-left: auto;
  cursor: pointer;
}

.inspiration-container .heading-text {
  font-family: Inter-SemiBold,sans-serif;
  font-size: 20px;
  line-height: 24.2px;
  color: #fff2e3;
}

/* Like Views section */

.like-views-container {
  padding: 34px 50px 34px 33px !important;
  align-items: flex-start;
  flex-wrap: wrap;
}

.like-views-container .header-container {
  display: flex;
  align-items: center;
}

.like-views-container .likeViews-elm-container {
  display: flex;
  flex-direction: column;
  gap: 23px;
  margin-top: 40px;
  width: 505px;
  max-height: 525px;
}

.like-views-container .userprofile {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  margin-right: 20px;
}

.like-views-container .text-container {
  font-family: Inter,sans-serif;
  font-size: 16px;
  line-height: 19.36px;
  color: #fff2e3;
}

.like-views-container .right-text-container.active-text {
  border-bottom: 1px solid;
  margin-right: 0 !important;
}

.like-views-container .text-data {
  font-family: Inter-SemiBold,sans-serif;
  font-size: 20px;
  line-height: 24.2px;
  color: #fff2e3;
}

/* Project details section*/
.project-details-container,
.share-details-container {
  width: 425px;
  padding: 32px;
  gap: 44px;
  border-radius: 24px;
  background-color: #1D1D1D;
  align-items: flex-start;
}

.project-details-container .header-container {
  display: flex;
  width: 100%;
}

.project-details-container .heading-text {
  font-family: Inter-SemiBold,sans-serif;
  font-size: 20px;
  line-height: 24.2px;
  color: #fff2e3;
}

.project-details-container .subheading-text {
  font-family: Inter,sans-serif;
  font-size: 12px;
  line-height: 14.52px;
  color: #7d7d7d;
  margin-top: 11px;
  margin-bottom: 44px;
}

.project-details-container .column-heading {
  font-family: Inter,sans-serif;
  font-size: 14px;
  line-height: 16.94px;
  color: #7d7d7d;
}

.project-details-container .column-text {
  font-family: Inter-Medium,sans-serif;
  font-size: 16px;
  line-height: 19.36px;
  color: #fff2e3;
}

.project-details-container .text-container {
  text-align: center;
  padding-right: 17px;
  border-right: 1px solid #545454;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 11px;
  max-width: 72px;
  width: 100%;
}

.project-details-container .text-container:last-child {
  border-right: none;
}

.project-details-container .project-elm-container {
  display: flex;
  flex-direction: column;
  gap: 57px;
  width: 100%;
}

.project-details-container .tags-container {
  margin-top: 20px;
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

/* Share details section */

.share-details-container {
  width: 525px;
  padding: 46px 53px;
  gap: 44px;
  border-radius: 21.22px;
  background-color: #1D1D1D;
  align-items: flex-start;
}

.share-details-container .share-elm-container {
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 100%;
}

.share-details-container .image-container {
  width: 415px;
  height: 267px;
  border-radius: 2.83px;
}

.share-details-container .logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}

.share-details-container .heading-text {
  font-family: Inter-SemiBold,sans-serif;
  font-size: 20px;
  line-height: 24.2px;
  color: #fff2e3;
}

.share-details-container .column-text {
  font-family: Inter,sans-serif;
  font-size: 14px;
  line-height: 16.94px;
  color: #7d7d7d;
}

.share-details-container .link-text {
  font-family: Inter-SemiBold,sans-serif;
  font-size: 16px;
  line-height: 19.36px;
  cursor: pointer;
  width: max-content;
  border-bottom: 1px solid #fff2e3;
  color: #fff2e3;
}



.MuiPickersPopper-paper .MuiDateCalendar-root .MuiYearCalendar-root {
  scrollbar-width: none;
}