.main-box {
    background-color: rgba(17, 17, 17, 1);
    height: 100vh;
}

.main-heading-first-box {
       font-family: 'Inter', sans-serif !important;

    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: 24.2px !important;
    color: rgba(255, 242, 227, 1) !important;

}

.addMilestoneBtn {
    background-color: rgba(13, 13, 13, 1) !important;
       font-family: 'Inter', sans-serif !important;

    font-size: 16px !important;
    text-transform: inherit !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    color: rgba(255, 242, 227, 1) !important;
    width:175px !important;
    padding: 10px 30px !important;
}

.view_all_heading {
       font-family: 'Inter', sans-serif !important;
       cursor:pointer !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 19.36px !important;
    color: rgba(255, 242, 227, 1) !important;
    text-decoration: underline !important;
}

.active_heading {
       font-family: 'Inter', sans-serif !important;

    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: 24.2px !important;
    color: rgba(255, 242, 227, 1) !important
}

.submessiongPendingCSS {
       font-family: 'Inter', sans-serif !important;

    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 16.94px !important;
    color: rgba(255, 242, 227, 1) !important;
    padding: 6px 16px !important;
    background-color: rgba(29, 29, 29, 1) !important;
    border-radius: 24px !important;
}

.cursor {
    cursor: pointer;
}

.flexBox {
    display: flex;
    gap: 12px;
    align-items: center;
}

.firstFirstGridPart {
    background-color: rgba(23, 23, 23, 1);
    border-radius: 10px;
    padding-bottom: 38px;
    margin-top: 24px;
}

.GridFirstPartCss {
    padding-inline: 60px;
    padding-top: 38px;
}

.sub-box-part {
    display: flex;
    gap: 12px;
    align-items: center;
}

.earningBoxCSSCircle {
    width: 26px;
    height: 13px;
    background: linear-gradient(224.84deg, #FFC29F 0%, #FFB9C7 16.15%, #CF8CCC 33.85%, #AB9EF5 50.52%, #6C98EE 67.71%, #1CBAE0 83.85%, #A8E5EC 100%);
    border-radius: 11px;
}
.MuiSlider-root.Mui-disabled {
    color:unset !important
}
.contractBoxCSSCircle {
    width: 26px;
    height: 13px;
    background: rgba(17, 17, 17, 1);
    border: 1px solid rgba(125, 125, 125, 1);
    border-radius: 11px;
}

.walletBoxCSSCircle {
    width: 26px;
    height: 13px;
    background: rgba(255, 242, 227, 1);
    border-radius: 11px;
}

.sub-part-heading {
    font-family: 'Inter', sans-serif !important;

    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    color: rgba(255, 242, 227, 1);

}

.sub-part-heading .span {
    font-weight: 700;
    margin-left: 10px;
}

.contract_start {
       font-family: 'Inter', sans-serif !important;

    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 14.52px !important;
    color: rgba(125, 125, 125, 1) !important;
    margin-top: 24px;
}

.start_date {
       font-family: 'Inter', sans-serif !important;

    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 14.52px !important;
    color: rgba(255, 242, 227, 1);
}


.start_box {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    align-items: center;
}

.active_box_parent{
    border: 1px solid rgba(97, 202, 120, 1);
    border-radius: 50% !important;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.active_milestone_box{
 border-radius: 50% !important;
 width:30px !important;
 height: 30px !important ;
 background-color: rgba(255, 242, 227, 1)!important ;
 font-family: 'Inter', sans-serif !important;

 font-size: 14px!important ;
 font-weight: 600!important ;
 line-height: 16.94px!important ;
display: flex;
justify-content: center;
align-items: center;
 color:rgba(17, 17, 17, 1)!important ;
}
.additional-resource-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 11.97px;
    border: 2.33px dashed #0D0D0D;
    width: 100%;
    padding:16px 12px !important
  }
  .upload-container {
    display: flex;
    width: 100%;

    height: 136px;
    justify-content: center;
    background-color: rgba(50, 50, 50, 1);
    border-radius: 11px;
}

  .container {
    display: flex;
    width: 100%;
    height: 115px;
    justify-content: center;
    background-color: #222;
    border-radius: 10px;
}
.link-container {
    width: 100%;
    border: 1.16px solid #3C3C3C;
    border-radius: 10px;
}
.link-text input {
    text-align: center !important;
}

.search-field {
    width: 100%;
    background-color: transparent;
    border: none;
    height: 49px;
    color: #fff2e3;
}

.default-text {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    line-height: 19.36px;
}


.link-text {
    height: 57px !important;
    text-align: center;
    border-radius: 10px;
    color: #fff2e3;
}

.upload-resource {
    padding: 0;
    height: max-content !important;
    border-bottom: 1px solid #fff2e3;
    background-color: transparent;
    border-radius: 0;
    color: #fff2e3;
    width: max-content;
}

.ongoing_milestone::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 12px;
    padding: 1px;
    background: linear-gradient(90.83deg, #3fa7e1 -0.01%, #7699ef 31.13%, #ae9df2 51.64%, #e8a5ca 72.11%, #f1b2c6 99.99%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    z-index: -1;
    mask-composite: exclude;
  }
.ongoing_milestone{
    position: relative;
    z-index: 1;
   padding: 31px 24px 0px 24px!important;
    border-radius: 10px !important;
    margin-top: -31px !important;
    background: rgba(34, 34, 34, 1);
}
.ongoing_milestone .MuiStepLabel-labelContainer{
    margin-left: 28px !important;
}
.ongoing_milestone .MuiStepLabel-iconContainer{
    border: 1.5px solid rgba(97, 202, 120, 1);
    padding:5px;
    border-radius:50%;
    margin:-7px;
}
.pending_milstone{
    padding: 0px 22px 0px 22px!important;
}

.complete_miletone_with_border .MuiStepIcon-root{
    color: rgba(97, 202, 120, 1) !important;
    font-family: 'Inter', sans-serif !important;
    font-size: 14px!important;
    font-weight: 600!important;
    line-height: 16.94px!important;
}


.pending_milstone_with_border{
    padding: 31px 24px 0px 24px!important;
    border-radius: 10px !important;
    border:1px solid rgba(95, 95, 95, 1) !important;
    margin-top:-31px !important;
}
.complete_miletone{
    padding: 0px 24px 0px 24px!important;
}
.ongoing_milestone  .MuiStepContent-root {
    padding-bottom: 24px !important;
}
.pending_milstone_with_border  .MuiStepContent-root {
    padding-bottom: 24px !important;
}

.complete_miletone .MuiStepIcon-root{
    color: rgba(97, 202, 120, 1) !important;
    font-family: 'Inter', sans-serif !important;
    font-size: 14px!important;
    font-weight: 600!important;
    line-height: 16.94px!important;
}

.remaining_milestone_style{
       font-family: 'Inter', sans-serif !important;

font-size: 16px !important;
cursor:pointer !important;
font-weight: 600 !important;
line-height: 19.36px !important;
text-decoration-line: underline !important;
text-decoration-style: solid !important;
color: rgba(255, 242, 227, 1) !important;
}


.download_icon{
    width:30px !important;
    height:30px !important;
    border-radius: 50% !important;
    background: rgba(84, 84, 84, 1) !important;
    justify-content: center !important;
    align-items: center !important;
    display: flex !important;

}

.submit-work-btn{
padding: 10px 24px 10px 24px !important;
border-radius: 7px !important;
background: rgba(255, 242, 227, 1) !important;
font-family: 'Inter', sans-serif !important;
font-size: 16px!important;
font-weight: 500!important;
line-height: 24px!important;
letter-spacing: 0.3799999952316284px!important;
text-decoration-skip-ink: none!important;
color:rgba(18, 18, 18, 1) !important;
text-transform: inherit !important;
}
.submit-work-box{
    display: flex !important;
    justify-content: space-between !important;
    margin-top: 20px !important;
}

.milestone_name_style{
    font-family: 'Inter', sans-serif !important;
font-size: 16px!important;
font-weight: 600!important;
line-height: 19.36px!important;
}
.upload-files_style_box {
    width: 100%;
    height: 100% !important;
    text-align: center;
    display: flex !important;
}
    .upload-files_style {
font-family: 'Inter', sans-serif !important;
font-size: 16px !important;
font-weight: 600 !important;
line-height: 19.36px !important;
text-align: center !important;
text-decoration-line: underline !important;
color: rgba(255, 242, 227, 1)!important;
text-decoration-style: solid !important;
  display: flex;
}
  
 .submit_work_box_main .horizontal-scroll {
    width:80% !important;
    padding-inline: 10% !important;
  }
  
  .list-no {
    color: #7d7d7d;
    padding-right: 12px;
    font-family: 'Inter-SemiBold', sans-serif !important;
    font-size: 20px;
    line-height: 24.2px;
  }
  
  .hint-text {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    line-height: 16.94px;
  }
  
  .filename-container-elm {
    color: #fff;
    width: max-content;
    border-radius: 10px;
    background-color: #252525;
    padding: 13px 15px;
    margin: 0 0 10px 0; /* Fixed missing unit on margin */
  }
  

  .view_submession_box_heading{
    font-family: 'Inter', sans-serif !important;
font-size: 16px !important;
font-weight: 600 !important;
line-height: 19.36px !important;
color:rgba(255, 242, 227, 1) !important
  }
  .view_submession_box{
    border:1px solid rgba(73, 73, 73, 1) !important;
    border-radius: 10px !important;
  }
  .view_submesion_number{
    width: 30px!important;
height: 30px!important;
border-radius: 50% !important;
background: rgba(255, 242, 227, 1)!important;
font-family: 'Inter', sans-serif !important;
color:rgba(47, 47, 47, 1) !important;
font-size: 14px!important;
font-weight: 600!important;
line-height: 16.94px!important;
justify-content: center !important;
align-items: center !important;
display: flex !important;
  }
.submit_work_heading{
    font-family: 'Inter', sans-serif !important;
font-size: 20px !important;
font-weight: 600 !important;
line-height: 24.2px !important;
color: rgba(255, 242, 227, 1) !important;
}

.waititng_for_approval_style{
    font-family: 'Inter', sans-serif !important;
font-size: 16px!important;
font-style: italic!important;
font-weight: 400!important;
line-height: 19.36px!important;
color: rgba(125, 125, 125, 1)!important;
}
.milestone_date_style {
    font-family: 'Inter', sans-serif !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 19.36px !important;
    color: rgba(255, 242, 227, 1) !important;
}

.file_type_css{    font-family: 'Inter', sans-serif !important;
font-size: 14px!important;
font-weight: 400!important;
line-height: 16.94px!important;
color:rgba(125, 125, 125, 1) !important

}

.After_requested_edits{
    font-family: 'Inter', sans-serif !important;
    font-size: 14px!important;
    font-style: italic!important;
    font-weight: 400!important;
    line-height: 16.94px!important;
    color:rgba(141, 141, 141, 1)!important;
    padding-bottom: 10px !important;
    margin-bottom: 17px !important;
    border-bottom: 1px solid rgba(73, 73, 73, 1);
}


.await_milestone::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 12px;
    padding: 1px;
    background: linear-gradient(90.83deg, #3fa7e1 -0.01%, #7699ef 31.13%, #ae9df2 51.64%, #e8a5ca 72.11%, #f1b2c6 99.99%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    z-index: -1;
    mask-composite: exclude;
  }

  .pending_milestone{
    padding: 0 22px;
  }
.await_milestone{
    position: relative;
    z-index: 0;
   padding: 31px 24px 0px 24px!important;
    border-radius: 10px !important;
    margin-top: -31px !important;
    background: rgba(34, 34, 34, 1);
}
.await_milestone .MuiStepLabel-labelContainer{
    margin-left: 28px !important;
}
.await_milestone .MuiStepLabel-iconContainer{
    border: 1.5px solid rgba(125, 125, 125, 1);
    padding:5px;
    border-radius:50%;
    margin:-7px;
}
.await_milestone  .MuiStepContent-root {
    padding-bottom: 24px !important;
}

.second_grid_main{
    border: 1.15px solid rgba(109, 109, 109, 1) !important;
    border-radius: 12px !important;
}

.for_profile_location{
    background-color: rgba(23, 23, 23, 1) !important;
    display: flex !important;
    justify-content: space-between !important;
    padding:  20px 24px  !important;
    border-radius: 12px 12px 0px  0px!important;
    align-items: center !important;
    border-bottom: 1px solid rgba(73, 73, 73, 1) !important

}


.profile_css{
    width: 48px !important;
    height: 48px !important;
}

.notifcation_box_css{
    margin: 25px 24px 0px 24px !important;
    display:flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    padding-bottom: 20px !important;
}
.profile_name_css{
    font-family: 'Inter', sans-serif !important;
    font-size: 16.33px !important;
    font-weight: 700 !important;
    line-height: 19.76px !important;
color : rgba(227, 227, 227, 1) !important ;
    
}

.location_name_css{
    font-family: 'Inter', sans-serif !important;
    font-size: 12.33px !important;
    font-weight: 400 !important;
    line-height: 19.76px !important;
color : rgba(227, 227, 227, 1) !important ;
    
}


.notification_css{
    font-family: 'Inter', sans-serif !important;
font-size: 20px!important;
font-weight: 600!important;
line-height: 24.2px!important;

color : rgba(255, 242, 227, 1) !important
}


.notification_count_css{
    background: linear-gradient(224.84deg, #FFC29F 0%, #FFB9C7 16.15%, #CF8CCC 33.85%, #AB9EF5 50.52%, #6C98EE 67.71%, #1CBAE0 83.85%, #A8E5EC 100%);
    width: 21px;
    height: 21px;
border-radius: 50% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Inter', sans-serif !important;

font-size: 12px!important;
font-weight: 700!important;
line-height: 14.52px!important;
color : rgba(255, 242, 227, 1) !important


}

.notification_timeline_css{
    font-family: 'Inter', sans-serif !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 21.28px !important;
    color :rgba(125, 125, 125, 1)!important
}
.notification_title_css{
    font-family: 'Inter', sans-serif !important;
font-size: 14px !important;
font-weight: 400 !important;
line-height: 21.28px !important;
color :rgba(255, 242, 227, 1) !important
}


.resource_link_heading{
    font-family: 'Inter', sans-serif !important;

font-size: 16px!important;
font-weight: 600!important;
line-height: 24.32px!important;
color: rgba(255, 242, 227, 1)!important;
}

.menu_icon_box{
    background-color: rgba(23, 23, 23, 1) !important;
    border: 1px solid rgba(95, 95, 95, 1) !important;
    border-radius: 10px !important;
    width:259px !important;
    position: absolute!important;
  left: -265px!important;
  right: 14px!important;
  top: -4px!important;
  z-index: 1!important;
}
.View_proposal_css{
    font-family: 'Inter', sans-serif !important;
font-size: 16px!important;
font-weight: 400!important;
line-height: 19.36px!important;

padding-block: 29px !important;
padding-left: 36px !important;
border-bottom: 1px solid rgba(50, 50, 50, 1) 
}

.end_contract_description{
    font-family: 'Inter', sans-serif !important;
font-size: 16px !important;
font-weight: 400 !important;
text-align: center !important;
line-height: 24.88px !important;
color: rgba(255, 242, 227, 1)!important;

}

.popUpHeadingEditMilestone{
    font-family: 'Inter', sans-serif !important;
font-size: 20px!important;
font-weight: 600!important;
line-height: 24px!important;
color : rgba(255, 242, 227, 1) !important
}



.raise_dispute_box{
    border-radius: 12px !important;
    background-color: rgba(86, 24, 20, 1) !important;
    padding: 26px 24px !important;
    margin-top: 44px !important;
}


.raise_dispute_title{
    font-family: 'Inter', sans-serif !important;
font-size: 14px !important;
font-weight: 500 !important;
line-height: 16.94px !important;
color:rgba(142, 142, 142, 1) !important

}

.userName_raise_date{
    font-family: 'Inter', sans-serif !important;
font-size: 14px!important;
font-weight: 600!important;
line-height: 16.94px!important;
color : rgba(125, 125, 125, 1) !important

}
.userName_raise_description{
    font-family: 'Inter', sans-serif !important;
font-size: 14px!important;
font-weight: 400!important;
line-height: 16.94px!important;
color : rgba(255, 242, 227, 1) !important

}
.userName_raise{
    font-family: 'Inter', sans-serif !important;
font-size: 14px!important;
font-weight: 600!important;
line-height: 16.94px!important;
color : rgba(255, 242, 227, 1) !important

}

.raise_dispute_all_box{
    display: flex !important;
    flex-direction: column !important;
    gap:17px
}

.raise_dispute_specific_box{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title_raised_dispute_css{
    font-family: 'Inter', sans-serif !important;
font-size: 14px!important;
font-weight: 400!important;
line-height: 16.94px!important;
color: rgba(125, 125, 125, 1)!important;
width:40% !important;

}
.value_raised_dispute_css{
    font-family: 'Inter', sans-serif !important;
font-size: 14px!important;
font-weight: 400!important;
line-height: 16.94px!important;
color: rgba(255, 242, 227, 1)!important;
width:100% ;
text-align: left;

}
.milstone_contract_ended{
    position: relative;
    z-index: 1;
   padding: 31px 24px 0px 24px!important;
    border-radius: 10px !important;
    margin-top: -31px !important;
}
.milstone_contract_ended .MuiStepLabel-labelContainer{
    margin-left: 28px !important;
}
.milstone_contract_ended .MuiStepLabel-iconContainer{
    padding:5px;
    border-radius:50%;
    margin:-7px;
}
.milstone_contract_ended .MuiStepIcon-root{
    color: #FF7878 !important;
    font-family: 'Inter', sans-serif !important;
    font-size: 14px!important;
    font-weight: 600!important;
    line-height: 16.94px!important;
}
.close_box{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}
.close_icon_style{
    color: white;
    cursor: pointer;
}
.link-text > input::placeholder {
    
    text-decoration: underline;
}