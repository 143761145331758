@import url('../colors/globalColors.css');

/* tag style start */

.tags {
    color: var(--secondary-color);
    height: 37px;
    font-size: 12px;
    line-height: 14.52px;
    text-align: center;
    vertical-align: middle;
    border: 0.5px solid var(--secondary-color);
    background-color: transparent;
    border-radius: 18.2px;
    font-family: Inter, sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 22px;
    padding-right: 22px;
    white-space: nowrap;
    cursor: pointer;
}

.tags-active {
    border-width: 1px;
    font-family: Inter-SemiBold, sans-serif;
    background-color: var(--grey-color);
    border-radius: 21px;
    column-gap: 10px;
}

/* Tags style end */

/* button style start*/

.button-elm {
    height: 44px;
    border-radius: 7px;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    font-family: Inter-SemiBold, sans-serif;
    line-height: 24px;
    letter-spacing: 0.38px;
    font-size: 16px;
    display: flex;
    border: none;
    gap: 10px;
    background-color: transparent;
}

.primary-btn {
    color: var(--secondary-color);
    background-color: var(--primary-gradient-color);
}

.secondary-btn {
    background-color: var(--secondary-color);
    color: var(--dark-color);
}

.disabled-btn {
    background-color: var(--disabled-color);
    color: var(--dark-color);
}

.default-btn {
    background-color: var(--default-color);
    color: var(--secondary-color);
}

/* Button style end */