
:root {
    --secondary-color: #fff2e3;
    --danger-color: #FF7878;
    --light-danger-color: #FFB9C7;
    --default-color: #0d0d0d;
    --dark-color: #121212;
    --grey-color: #323232;
    --disabled-color: #7d7d7d;
    --page-color: #111;
    --primary-gradient-color: linear-gradient(224.84deg, #FFC29F 0%, #FFB9C7 16.15%, #CF8CCC 33.85%, #AB9EF5 50.52%, #6C98EE 67.71%, #1CBAE0 83.85%, #A8E5EC 100%);
}
