.multirange_container {

  display: flex;
  align-items: center;
  justify-content: center;
}

.multirange_container input {
  width: 800px !important;
  position: absolute !important;
}

.multirange_slider {
  position: relative;
  width: 800px;
  bottom: 2px;

}

.multirange_slider__track,
.multirange_slider__range,
.multirange_slider__left-value,
.multirange_slider__right-value {
  position: absolute;
}

.multirange_slider__track,
.multirange_slider__range {
  border-radius: 3px;
  height: 5px;
}

.multirange_slider__track {
  background-color: #3D3B3B;
  width: 100%;
  z-index: 1;
}

.multirange_slider__range {

  background: linear-gradient(224.84deg, #FFC29F 0%, #FFB9C7 16.15%, #CF8CCC 33.85%, #AB9EF5 50.52%, #6C98EE 67.71%, #1CBAE0 83.85%, #A8E5EC 100%);

  z-index: 2;
}

.multirange_slider__left-value,
.multirange_slider__right-value {
  color: #dee2e6;
  font-size: 12px;
  margin-top: 40px;
}

.multirange_slider__left-value {
  left: 20px;
}

.multirange_slider__right-value {
  right: 270px;
}

/* Removing the default appearance */
.multirange_thumb,
.multirange_thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.multirange_thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 800px;
  outline: none;
}

.multirange_thumb--left {
  z-index: 33;
  opacity: 0;
}

.multirange_thumb--right {
  z-index: 44;
  opacity: 0;
}

.multirage_dot {
  position: absolute;
  width: 17px;
  height: 17px;
  background: #FFF2E3;
  border-radius: 50%;
  bottom: -10px;
  z-index: 11;

}

/* For Chrome browsers */
.multirange_thumb::-webkit-slider-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;

  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.multirange_thumb::-moz-range-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;

  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}


.multirange_container input[type="range"]::-moz-range-track,
.multirange_container input[type="range"]::-moz-range-progress {
  opacity: 0;

}

.multirange_container input[type="range"]::-webkit-slider-runnable-track {
  opacity: 0;
}

.multirange_slider_minmax {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  color: #797979;

}

.multirange_slider_minmax_value {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  text-align: left;

  color: #FFF2E3;
  margin-top: 7px;
}

.multirange_slider__to {
  color: #797979;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
}

.min_max_value_variables {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}