@font-face {
    font-family: 'Inter';
    src: url('./fontlist/InterFont/Inter-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter-Black';
    src: url('./fontlist/InterFont/Inter-Black.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter-Bold';
    src: url('./fontlist/InterFont/Inter-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter-SemiBold';
    src: url('./fontlist/InterFont/Inter-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter-ExtraBold';
    src: url('./fontlist/InterFont/Inter-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter-Light';
    src: url('./fontlist/InterFont/Inter-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter-ExtraLight';
    src: url('./fontlist/InterFont/Inter-ExtraLight.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter-Thin';
    src: url('./fontlist/InterFont/Inter-Thin.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter-Medium';
    src: url('./fontlist/InterFont/Inter-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter-VariableFont';
    src: url('./fontlist/InterFont/Inter-VariableFont_slnt,wght.ttf') format('truetype');
}
